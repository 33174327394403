import { ROUTES } from './routes';

export const routesRestartWithoutGiveUp = [
  ROUTES.GRADING_SELECT_SPECS,
  ROUTES.GRADING_QUESTIONS,
  ROUTES.GRADING_BUY_PRODUCT,
  ROUTES.TRADE_IN_SUCCESS,
  ROUTES.OPERATOR_PORTAL,
  ROUTES.OPERATOR_PORTAL_TRADEINS,
  ROUTES.OPERATOR_PORTAL_INVITE,
  ROUTES.OPERATOR_PORTAL_DASHBOARD,
];
