import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { includes } from 'ramda';

import { tv } from 'tailwind-variants';

import { useUserAuth } from '@/context/user-auth-provider';
import { twMerge } from 'tailwind-merge';

import TopHeaderOperatorInfos from '@@/StoreAndStoreOperator/components/top-header-operator-infos';

import { ROUTES } from '@/constants';

import Footer from '@/components/footer';

type Props = {
  children: React.ReactNode;
  className?: string;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  disableCenterDesk?: boolean;
  skipUserAuthCheck?: boolean;
};

const container = tv({
  base: 'w-full h-full',
  variants: {
    type: {
      center: 'md:flex md:flex-col md:items-center md:justify-center',
    },
    header: {
      true: '',
      false: '',
    },
    footer: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      header: true,
      footer: true,
      class: 'min-h-[calc(100vh-87px)] md:min-h-[calc(100vh-95px)] pt-3 pb-6 md:pb-8',
    },
    {
      header: true,
      footer: false,
      class: 'min-h-[calc(100vh-52px)] md:min-h-[calc(100vh-41px)] pt-3',
    },
    {
      header: false,
      footer: true,
      class: 'min-h-[calc(100vh-46px)]',
    },
    {
      header: false,
      footer: false,
      class: 'min-h-screen pt-10 md:pt-0',
    },
  ],
});

const Container = ({
  children,
  className,
  withoutHeader = false,
  withoutFooter = false,
  disableCenterDesk = false,
  skipUserAuthCheck = false,
}: Props) => {
  const { storeData, isSignedIn } = useUserAuth();
  const [isClient, setIsClient] = useState(false);
  const { push, pathname } = useRouter();

  const shouldRedirectNotAuthed =
    !skipUserAuthCheck && !storeData && !includes(pathname, [ROUTES.OPERATOR_PORTAL, ROUTES.LOGIN, ROUTES.ONLY_ADMIN]);

  const shouldRenderHeader = !withoutHeader && isClient && isSignedIn;

  useEffect(() => {
    setIsClient(true);

    if (shouldRedirectNotAuthed) push(ROUTES.OPERATOR_PORTAL);
  }, [storeData]);

  return (
    <>
      {shouldRenderHeader && <TopHeaderOperatorInfos />}

      <div className={twMerge('container w-full px-4 md:mx-auto md:max-w-xl', className)}>
        <div
          className={container({
            type: disableCenterDesk ? undefined : 'center',
            header: !withoutHeader,
            footer: !withoutFooter,
          })}>
          <div className={twMerge('h-full w-full', !withoutHeader ? 'py-2' : 'pt-10 md:pt-0')}>{children}</div>
        </div>
      </div>

      {!withoutFooter && <Footer />}
    </>
  );
};

export default Container;
